import BaseBean from "@/utils/BaseBean";

export interface IManualListDataObj {
    utilInst:RepairManualListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    cxData:Array<any>
}

export default class RepairManualListUtil extends BaseBean{
    public dataObj:IManualListDataObj

    constructor(proxy:any,dataObj:IManualListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        //构建网点下拉数据
        this.dataObj.cxData=await this.utils.ToolsProviderApi.getAllCx();
    }
}